@import 'src/styles/mixins.scss';

.fields {
  width: 27rem;
  max-width: 90vw;
  padding-bottom: 2rem;
  margin: auto;
}
button.button {
  min-width: 16rem;
}
